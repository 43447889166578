// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from '@seegno-labs/react-components/styles';
import { ifProp, theme } from 'styled-tools';
import DefaultModal, { BaseModalBackground } from 'styled-react-modal';
import IconButton from 'components/core/button/icon-button';
import React, { type Node, useCallback, useState } from 'react';
import closeIcon from 'assets/svg/close-21px.svg';
import styled from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * Export `ModalProps` type.
 */

export type ModalProps = {|
  children: Node,
  isOpen: boolean,
  onClose: () => void
|};

/**
 * Export `ModalBackground` styled component.
 */

export const ModalBackground = styled(BaseModalBackground)`
  background-color: ${color('secondary')};
  display: block;
  opacity: ${ifProp('animated', 1, 0)};
  overflow: ${ifProp('showOverflow', 'auto', 'hidden')};
  padding-top: ${units(12.5)};
  transition: opacity ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.modal.backdrop')};
`;

/**
 * `StyledModal` styled component.
 */

const StyledModal = DefaultModal.styled`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  min-height: 100%;

  ${media.min('md')`
    grid-column-gap: ${units(4)};
  `}
`;

/**
 * `ModalContainer` styled component.
 */

const ModalContainer = styled.div`
  background-color: ${color('white')};
  border-radius: ${units(1)} ${units(1)} 0 0;
  grid-column: 2 / -2;
  height: 100%;
  justify-self: center;
  max-width: 1141px;
  overflow: hidden;
  position: relative;
  transform: translateY(${ifProp('isVisible', 0, 100)}%);
  transition: transform ${theme('animations.slowTransition')};
  width: 100%;
  will-change: transform;
`;

/**
 * `ModalCloseButton` styled component.
 */

const ModalCloseButton = styled(IconButton)`
  color: ${color('grey400')};
  position: absolute;
  right: ${units(2)};
  top: ${units(2)};

  ${media.min('md')`
    top: ${units(1.5)};
  `}
`;

/**
 * Export `Modal` component.
 */

export function Modal(props: ModalProps): Node {
  const { children, isOpen, onClose, ...rest } = props;
  const [animated, setAnimated] = useState(isOpen);
  const [showOverflow, setShowOverflow] = useState(isOpen);
  const isMobile = useBreakpoint('xs', 'max');
  const afterOpen = useCallback(() => {
    // This allows the animations to happen.
    setTimeout(() => setAnimated(true), 10);
    // This prevents the scrollbar to appear in then middle of the animation.
    setTimeout(() => setShowOverflow(true), 800);
  }, []);

  const beforeClose = useCallback(() => new Promise(resolve => {
    setAnimated(false);
    setShowOverflow(false);
    setTimeout(resolve, 200);
  }), []);

  return (
    <StyledModal
      {...rest}
      afterOpen={afterOpen}
      backgroundProps={{ animated, showOverflow }}
      beforeClose={beforeClose}
      isOpen={isOpen}
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
    >
      <ModalContainer isVisible={animated}>
        <ModalCloseButton
          aria-label={'Close Modal'}
          icon={closeIcon}
          iconSize={isMobile ? '16px' : '21px'}
          onClick={onClose}
          size={isMobile ? 'small' : 'large'}
        />

        {children}
      </ModalContainer>
    </StyledModal>
  );
}
