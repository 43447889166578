// @flow

/**
 * Module dependencies.
 */

import { animations } from './animations';
import { boxShadows } from './box-shadows';
import { colors } from './colors';
import { createTheme } from '@seegno-labs/react-components/styles';
import { keyframes } from './keyframes';
import { typography } from './type';
import { zIndex } from './z-index';

/**
 * Export `theme`.
 */

export const theme = createTheme({
  animations,
  boxShadows,
  colors,
  grid: {
    container: 1440,
    gutters: {
      base: 16,
      xs: 24
    }
  },
  keyframes,
  typography,
  zIndex
});
