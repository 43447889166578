// @flow

/**
 * Export `zIndex`.
 */

export const zIndex = {
  cookiesBanner: 97,
  mobileMenu: 98,
  modal: {
    backdrop: 100,
    closeButton: 102,
    container: 101
  },
  navbar: 99,
  snackbar: 200
};
