// @flow

/**
 * Module dependencies.
 */

import { keyframes as keyframe } from 'styled-components';

/**
 * `bounceIn` keyframe.
 */

const bounceIn = keyframe`
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: scale3d(0, 0, 0);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;

/**
 * `bounceOut` keyframe.
 */

const bounceOut = keyframe`
  from,
  25%,
  50%,
  100%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0);
  }
`;

/**
 * `fadeOut` keyframe.
 */

const fadeOut = keyframe`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * `Shake` keyframe.
 */

const shake = keyframe`
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(8px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(-8px, 0, 0);
  }

  from,
  to {
    transform: translate3d(0, 0, 0);
  }
`;

/**
 * `SlideIn` keyframe.
 */

const slideIn = keyframe`
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
`;

/**
 * Export `keyframes`.
 */

export const keyframes = {
  bounceIn,
  bounceOut,
  fadeOut,
  shake,
  slideIn
};
