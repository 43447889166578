// @flow

/**
 * Module dependencies.
 */

import { Icon } from '@seegno-labs/react-components/media';
import { color, states, units } from '@seegno-labs/react-components/styles';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import React, { type Node } from 'react';
import RouterLink from 'components/core/links/router-link';
import slykBorderIcon from 'assets/svg/slyk-border.svg';
import styled, { css } from 'styled-components';

/**
 * Button sizes.
 */

const buttonSizes = {
  extraSmall: units(3),
  large: units(7),
  medium: units(6),
  small: units(4)
};

/**
 * Icon sizes.
 */

const iconSizes = {
  extraSmall: '16px',
  large: '40px',
  medium: '32px',
  small: '24px'
};

/**
 * Export `IconButtonProps` type.
 */

export type IconButtonProps = {|
  'aria-label'?: string,
  as?: Node,
  children?: Node,
  className?: string,
  colorTheme?: 'blue' | 'white',
  disabled?: boolean,
  href?: string,
  icon: string,
  iconSize?: string,
  rel?: string,
  size: $Keys<typeof buttonSizes>,
  target?: '_blank' | '_self' | '_parent' | '_top',
  to?: string,
  type?: string
|};

/**
 * `OutlinedMaskIcon` styled component.
 */

const OutlinedMaskIcon = styled(Icon).attrs({ icon: slykBorderIcon })`
  grid-area: icon;
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  grid-area: icon;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span`
  align-items: center;
  display: grid;
  grid-template-areas: 'icon';
  grid-template-columns: 100%;
  height: ${prop('size')};
  justify-items: center;
  transition: color ${theme('animations.fastTransition')};
  width: ${prop('size')};

  ${ifProp('hasChildren', css`
    margin-right: ${units(1)};
  `)}
`;

/**
 * `Button` styled component.
 */

const Button = styled.button.attrs(({ as, href, to, type }: IconButtonProps) => {
  const element = as || to && RouterLink || href && 'a' || 'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  padding: 0;
  text-align: center;

  ${ifProp('hasChildren', css`
    align-items: center;
    display: flex;
    flex-direction: row;
  `)}

  ${switchProp('colorTheme', {
    blue: css`
      color: ${color('blue500')};

      &:hover,
      &:focus {
        color: ${color('blue600')};
      }
    `,
    white: css`
      color: ${color('white')};

      &:hover,
      &:focus {
        color: ${color('primary')};
      }
    `
  })}

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${states.action`
    outline: none;
    text-decoration: none;
  `}
`;

/**
 * `IconButton` component.
 */

const IconButton = (props: IconButtonProps): Node => {
  const { children, colorTheme, icon, iconSize, size, ...rest } = props;

  return (
    <Button
      colorTheme={colorTheme}
      hasChildren={!!children}
      {...rest}
    >
      <Wrapper
        hasChildren={!!children}
        size={buttonSizes?.[size]}
      >
        <OutlinedMaskIcon size={buttonSizes?.[size]} />

        <StyledIcon
          icon={icon}
          size={iconSize ?? iconSizes?.[size]}
        />
      </Wrapper>

      {children}
    </Button>
  );
};

/**
 * Default props.
 */

IconButton.defaultProps = {
  colorTheme: 'blue',
  size: 'medium'
};

/**
 * Export `IconButton` component.
 */

export default IconButton;
