// @flow

/**
 * Module dependencies.
 */

import { reduce } from 'lodash';

/**
 * Base palette.
 */

const basePalette = {
  blue: ['#d6e4ff', '#adc8ff', '#84a9ff', '#6690ff', '#3366ff', '#254edb', '#1939b7', '#102693', '#091a7a', '#0b192d'],
  green: ['#dbfcd6', '#b2f9af', '#83ee89', '#61dd74', '#33c758', '#25ab53', '#198f4d', '#107345', '#095f40'],
  grey: ['#f9fafa', '#f6f7f8', '#dcdfe3', '#b7bec6', '#8d97a5', '#778393', '#667281', '#596370', '#4d5661', '#59677c'],
  indigo: ['#dfdefc', '#c0bffa', '#9d9cf2', '#817fe6', '#5957d6', '#413fb8', '#2d2b9a', '#1c1b7c', '#091a7a'],
  nightBlue: ['#d1e6f5', '#a6ccec', '#6f9bc6', '#40648e', '#122744', '#0d1e3a', '#091630', '#050f27', '#030a20'],
  orange: ['#fff2cc', '#ffe199', '#ffcc66', '#ffb73f', '#ff9500', '#db7600', '#b75b00', '#934300', '#7a3200'],
  purple: ['#faddfd', '#f2bdfb', '#e299f5', '#cd7deb', '#b054de', '#8b3dbe', '#692a9f', '#4b1a80', '#35106a'],
  red: ['#fee5d7', '#fdc4b0', '#fa9c89', '#f6776a', '#f13b3b', '#cf2b39', '#ad1d36', '#8b1232', '#730b2f']
};

/**
 * Palette.
 */

const palette = reduce(basePalette, (result, values, key) => ({
  ...result,
  ...values.reduce((previous, hex, index) => ({
    ...previous,
    [`${key}${(index + 1) * 100}`]: hex
  }), {})
}), {});

/**
 * Export `colors`.
 */

export const colors = {
  ...palette,
  black: '#000000',
  primary: palette.blue500,
  secondary: palette.nightBlue500,
  white: '#ffffff'
};
