// @flow

/**
 * Module dependencies.
 */

import { Link as GatsbyLink } from 'gatsby';
import React, { type Node, forwardRef } from 'react';

/**
 * `GetPropsArg` type.
 */

type GetPropsArg = {
  href: string,
  isCurrent: boolean,
  isPartiallyCurrent: boolean,
  location: Object
};

/**
 * `Props` type.
 */

type Props = {
  'aria-label'?: string,
  children: Node,
  className?: string,
  getProps?: GetPropsArg => Object,
  onClick?: Function,
  partiallyActive?: boolean,
  replace?: boolean,
  state?: Object,
  to: string
};

/**
 * `RouterLink` component.
 */

function RouterLink(props: Props, ref): Node {
  const {
    'aria-label': ariaLabel,
    children,
    className,
    getProps,
    onClick,
    partiallyActive,
    replace,
    state,
    to
  } = props;

  return (
    <GatsbyLink
      aria-label={ariaLabel}
      className={className}
      getProps={getProps}
      innerRef={ref}
      onClick={onClick}
      partiallyActive={partiallyActive}
      replace={replace}
      state={state}
      to={to}
    >
      {children}
    </GatsbyLink>
  );
}

/**
 * Export `RouterLink` component.
 */

export default forwardRef<Props, any>(RouterLink);
