// @flow

/**
 * Module dependencies.
 */

import { css } from 'styled-components';
import { media } from '@seegno-labs/react-components/styles';

/**
 * Font families.
 */

const fontFamily = {
  inter: '"Inter", sans-serif',
  saira: '"Saira", sans-serif'
};

/**
 * `h1` style.
 */

const h1 = css`
  font-family: ${fontFamily.saira};
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 52px;

  ${media.min('md')`
    font-size: 79px;
    font-weight: 300;
    letter-spacing: -3px;
    line-height: 80px;
  `}
`;

/**
 * `h2` style.
 */

const h2 = css`
  font-family: ${fontFamily.saira};
  font-size: 40px;
  font-weight: 300;
  letter-spacing: -1px;
  line-height: 44px;

  ${media.min('md')`
    font-size: 56px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 56px;
  `}
`;

/**
 * `h3` style.
 */

const h3 = css`
  font-family: ${fontFamily.saira};
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 32px;

  ${media.min('md')`
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 48px;
  `}
`;

/**
 * `h4` style.
 */

const h4 = css`
  font-family: ${fontFamily.saira};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 28px;

  ${media.min('md')`
    font-size: 28px;
    line-height: 32px;
  `}
`;

/**
 * `h5` style.
 */

const h5 = css`
  font-family: ${fontFamily.inter};
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 24px;

  ${media.min('md')`
    font-size: 22px;
    line-height: 26px;
  `}
`;

/**
 * `h6` style.
 */

const h6 = css`
  font-family: ${fontFamily.inter};
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 24px;

  ${media.min('md')`
    font-size: 20px;
    line-height: 26px;
  `}
`;

/**
 * `label` style.
 */

const label = css`
  font-family: ${fontFamily.inter};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 20px;

  ${media.min('md')`
    font-size: 16px;
    line-height: 22px;
  `}
`;

/**
 * `paragraph` style.
 */

const paragraph = css`
  font-family: ${fontFamily.inter};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 18px;

  ${media.min('md')`
    font-size: 14px;
    line-height: 20px;
  `}
`;

/**
 * `small` style.
 */

const small = css`
  font-family: ${fontFamily.inter};
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 14px;

  ${media.min('md')`
    font-size: 12px;
    line-height: 16px;
  `}
`;

/**
 * Export types to be generated.
 */

const styles = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  p: paragraph, // eslint-disable-line id-length
  small
};

/**
 * Export `type`.
 */

export const typography = {
  fontFamily,
  styles
};
