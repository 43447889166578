// @flow

/**
 * Module dependencies.
 */

import { color, globalStyles } from '@seegno-labs/react-components/styles';
import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

/**
 * `GlobalStyle` component.
 */

export const GlobalStyle = createGlobalStyle`
  ${globalStyles} /* stylelint-disable-line selector-type-no-unknown */

  :root {
    --reach-tabs: 1;
  }

  html {
    ${theme('typography.styles.p')}
  }

  p {
    margin: 0;
  }

  body {
    color: ${color('grey700')};
  }

  svg {
    display: block;
  }
`;
