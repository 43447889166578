// @flow

/**
 * `slowAnimationTime` time animation.
 */

const slowAnimationTime = 650;

/**
 * `regularAnimationTime` time animation.
 */

const regularAnimationTime = 500;

/**
 * `fastAnimationTime` time animation.
 */

const fastAnimationTime = 200;

/**
 * Animation durations.
 */

const duration = {
  fast: `${fastAnimationTime}ms`,
  regular: `${regularAnimationTime}ms`,
  slow: `${slowAnimationTime}ms`
};

/**
 * `defaultTransition` transition.
 */

const defaultTransition = `${duration.regular} ease`;

/**
 * `fastTransition` transition.
 */

const fastTransition = `${duration.fast} ease`;

/**
 * `slowTransition` transition.
 */

const slowTransition = `${duration.slow} ease`;

/**
 * Export `animations`.
 */

export const animations = {
  defaultTransition,
  fastTransition,
  slowTransition
};
