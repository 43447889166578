// @flow

/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

/**
 * Export `useMediaQuery`.
 */

export function useMediaQuery(query: string): boolean {
  const isClient = typeof window === 'object';
  const [matches, setMatches] = useState(() => {
    if (!isClient) {
      return false;
    }

    return window.matchMedia(query).matches;
  });

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);

    media.addListener(listener);

    return () => {
      media.removeListener(listener);
    };
  }, [query]);

  return matches;
}
