
/**
 * Module dependencies.
 */

import { GlobalStyle } from 'styles/global';
import React from 'react';
import Root from 'components/root';

/**
 * Export `wrapRootElement`.
 */

export const wrapRootElement = ({ element }) => (
  <Root>
    {element}
  </Root>
);

/**
 * Export `wrapPageElement`.
 */

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />

    {element}
  </>
);
