// @flow

/**
 * Module dependencies.
 */

import { ModalContextProvider } from 'components/core/modal/provider';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: Node
};

/**
 * `Root` component.
 */

const Root = ({ children }: Props): Node => (
  <ThemeProvider theme={theme}>
    <ModalContextProvider>
      {children}
    </ModalContextProvider>
  </ThemeProvider>
);

/**
 * Export `Root` component.
 */

export default Root;
