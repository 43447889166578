// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-api-js": () => import("./../../../src/templates/api.js" /* webpackChunkName: "component---src-templates-api-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-payspace-terms-of-service-js": () => import("./../../../src/templates/payspace-terms-of-service.js" /* webpackChunkName: "component---src-templates-payspace-terms-of-service-js" */),
  "component---src-templates-payspaces-js": () => import("./../../../src/templates/payspaces.js" /* webpackChunkName: "component---src-templates-payspaces-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-slykigai-js": () => import("./../../../src/templates/slykigai.js" /* webpackChunkName: "component---src-templates-slykigai-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-user-terms-of-service-js": () => import("./../../../src/templates/user-terms-of-service.js" /* webpackChunkName: "component---src-templates-user-terms-of-service-js" */)
}

